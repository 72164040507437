.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
  max-height: 70vh;
  overflow: auto;

  > standard-page-section > standard-card > div {
    border-width: 0 !important;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0;
}

:disabled {
  .p-checkbox,
  .p-radiobutton,
  .p-inputswitch-checked,
  .p-multiselect,
  .p-editor-container {
    cursor: default !important;
    pointer-events: none;

    @extend .p-disabled;
  }

  .p-hidden-accessible,
  .p-multiselect-token-icon,
  .p-multiselect-clear-icon,
  .p-password .p-password-clear-icon,
  .p-password i.pi,
  .p-chip .pi-chip-remove-icon {
    display: none;
  }
}

// The base prime.min.scss positions this wrong for some reason, hopefully can remove in a future release
.p-password .p-password-clear-icon {
  margin-top: 0;
}

// Ensure certain tooltips are wider than others,
// which nice wrapping, but not too wide and not too narrow
.p-tooltip.p-tooltip-lg > .p-tooltip-text {
  width: min(260px, calc(100vw - 2rem)) !important;
}

// For tables inside of cards, we want this to be rounded
.rounded-top .p-datatable-thead > tr {
  > th:first-child {
    border-top-left-radius: var(--bs-border-radius);
  }

  > th:last-child {
    border-top-right-radius: var(--bs-border-radius);
  }
}

// Make badges tighter
.p-badge {
  border-radius: 50px;
  padding: 0 0.25rem;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 0.75rem;

  font-weight: normal;
  min-width: 0;
  height: 1rem;
  line-height: 1rem;
}

.p-overlay-badge .p-badge {
  transform: translate(25%, -25%) !important;
}

/* For tables with frozen columns, prevents certain standard fields from overlapping the frozen columns */
.p-datatable-scrollable-table {
  .p-multiselect-clearable {
    position: inherit;
  }
  .p-multiselect-label-container {
    padding-right: 0;
  }
  p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 0px;
  }
  .p-icon-wrapper {
    align-items: center;
    position: inherit;
  }
  .p-icon-wrapper:has(.p-multiselect-clear-icon) {
    margin-left: auto;
  }
  .p-multiselect-clear-icon {
    position: inherit;
    top: initial;
    margin-top: 0;
  }

  .dtInputContainer {
    position: inherit !important;
    font-size: var(--bs-input-font-size);
    min-height: var(--bs-h-form-control);
    color: var(--bs-body-color);
    background: var(--bs-body-bg);
    padding: 10px 12px;
    border: 1px solid var(--bs-tertiary-color);
    transition: var(--bs-transition-base);
    -webkit-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    display: flex;
    align-items: center;
    min-height: auto;

    input.p-inputtext {
      border: 0;
      padding: 0 !important;
    }
    .p-calendar {
      position: inherit;
    }
    .p-calendar input {
      min-height: auto;
    }
    .timezoneText,
    .calendarIcon {
      position: inherit !important;
    }
    .calendarIcon .h-form-control {
      min-height: auto;
    }
  }
  //Add more space to 3 dot selector
  td.p-element.d-none.d-md-table-cell.text-center.p-frozen-column:nth-child(3) {
    padding-right: 1rem;
  }
}
